import { Observable, finalize } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoadingService } from './loading.service';
import { environment } from '@core/environments/environment';
import { Pagination } from '@core/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private http = inject(HttpClient);
  private loadingService = inject(LoadingService);
  private BASE_URL = environment.ADMIN_BASE_URL + '/v1/report';
  private KYC_BASE_URL = environment.ADMIN_BASE_URL + '/v1/kyc';
  private BANK_BASE_URL = environment.ADMIN_BASE_URL + '/v1/bank';
  private ACCOUNT_BASE_URL = environment.ADMIN_BASE_URL + '/v1/account';

  constructor() { }

  getOrderReport(formData: any, accountID: string): Observable<any> {
    let params = new HttpParams();

    params = params.set('account_id', accountID);

    // Append dates only if they are provided
    if (formData.start_date) {
      params = params.set('start_date', formData.start_date);
    }
    if (formData.end_date) {
      params = params.set('end_date', formData.end_date);
    }
    if (formData.status) {
      params = params.set('status', formData.status);
    }
    return this.http.get(
      `${this.BASE_URL}/get-order-reports`, { params }
    );
  }
  
  getNavReport(formData: any, accountID: string): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/get-nav-reports?type=${formData.type}&account_id=${accountID}`
    );
  }
  getAccountStatementReport(formData: any, account_id: string): Observable<any> {
    const requestBody = {
      ...formData,  
      account_id        
    };
    return this.http.post(
      `${this.BANK_BASE_URL}/transaction-statement-generate`,
      requestBody
    );
  }
  getIndividualClientDirectoryReport(accountID: string): Observable<any> {
    return this.http.get(
      `${this.KYC_BASE_URL}/export-individual-kyc-data?account_id=${accountID}`
    );
  }
  getCoperateClientDirectoryReport(accountID: string): Observable<any> {
    return this.http.get(
      `${this.KYC_BASE_URL}/export-corporate-kyc-data?account_id=${accountID}`
    );
  }

  // Global

  getWalletReport(formData: any): Observable<any> {
    let params = new HttpParams();

    // Append dates only if they are provided
    if (formData.start_date) {
      params = params.set('start_date', formData.start_date);
    }
    if (formData.end_date) {
      params = params.set('end_date', formData.end_date);
    }
    return this.http.get(
      `${this.BASE_URL}/export-transactions`, { params }
    );
  }

  getClientDirectoryReport(formData: any): Observable<any> {
    let params = new HttpParams();

    // Append dates only if they are provided
    if (formData.start_date) {
      params = params.set('start_joined_date', formData.start_date);
    }
    if (formData.end_date) {
      params = params.set('end_joined_date', formData.end_date);
    }
  
    return this.http.get(`${this.BASE_URL}/export-client-directory-bio-data`, { params });
  
  }
  getFundedAccountList(
    data: Pagination = new Pagination()
  ): Observable<any> {
    const uniqueStateUrl = 'get-funded-account-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    const paginationInstance = new Pagination(data);
    const params = new HttpParams()
      .set('take', paginationInstance.size.toString())
      .set('skip', paginationInstance.skip.toString());

    return this.http
      .get<any>(`${this.ACCOUNT_BASE_URL}/get-client-with-fund`, { params })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getActiveAndInactiveAccountList(
    data: Pagination = new Pagination(), type: string, url: string
  ): Observable<any> {
    const uniqueStateUrl = url;
    this.loadingService.setLoading(uniqueStateUrl, true);

    const paginationInstance = new Pagination(data);
    const params = new HttpParams()
      .set('take', paginationInstance.size.toString())
      .set('skip', paginationInstance.skip.toString())
      .set('report_type', type);

    return this.http
      .get<any>(`${this.BASE_URL}/get-active-and-inactive-clients`, { params })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getGlobalPortfolioList(
    data: Pagination = new Pagination()
  ): Observable<any> {
    const uniqueStateUrl = 'get-portfolio-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    const paginationInstance = new Pagination(data);
    const params = new HttpParams()
      .set('take', paginationInstance.size.toString())
      .set('skip', paginationInstance.skip.toString());

    return this.http
      .get<any>(`${this.BASE_URL}/get-global-portfolio-balance`, { params })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getCientWithFundsReport(formData: any, export_format: string): Observable<any> {

    let params = new HttpParams();

    params = params.set('export_format', export_format);
    // Append dates only if they are provided
    if (formData.start_date) {
      params = params.set('start_date', formData.start_date);
    }
    if (formData.end_date) {
      params = params.set('end_date', formData.end_date);
    }


    return this.http.get(
      `${this.BASE_URL}/get-client-with-fund-report`, { params }
    );
  }
  getGlobalPortfolioReport(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/export-global-portfolio-balance-file`
    );
  }


  getActiveAndInactiveClientCSVReport( report_type: string): Observable<any> {
    console.log('csv');
    return this.http.get(
      `${this.BASE_URL}/export_active_inactive_or_clients_report?report_type=${report_type}`
    );
  }
  getActiveAndInactiveClientPDFReport( report_type: string): Observable<any> {
    console.log('pdf');
    return this.http.get(
      `${this.BASE_URL}/export_active_inactive_or_clients_report?report_type=${report_type}`
    );
  }
}
